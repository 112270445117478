import * as React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import Seo from '../components/seo';

import {HomeSplash, RowWrapper, TextCenter} from './contact.module.css';

const GMaps =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d166655.30827522842!2d-123.2639867747921!3d49.2576507715125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548673f143a94fb3%3A0xbb9196ea9b81f38b!2sVancouver%2C%20BC!5e0!3m2!1sen!2sca!4v1621529708903!5m2!1sen!2sca'; // google maps embed

const GStyle = {
  border: `0`,
};

const JotformUrl = 'https://form.jotform.com/193435364303253'; //Jotform embed URL from Iframe

const IStyle = {
  width: `100%`,
  height: `580px`,
  overflow: `hidden`, // hide scrolls
  scrolling: `no`,
  border: `none`,
  margin: `0 0 3em 0`,
  padding: `0`,
};

const Heading = styled.h1`
  color: var(--blue);
  text-align: center;
`;

const Contact = () => (
    <Layout>
    <Seo title='Contact Top1 Painting'
         description="Contact Top1 Painting &amp; Reno's" />
    <div className={HomeSplash}>
      <iframe
          title="Top1 Painting and Reno's"
          src={GMaps}
          width='600'
          height='500'
          frameBorder='0'
          style={GStyle}
          allowFullScreen='true'
          aria-hidden='false'
      />
    </div>

    <div className={RowWrapper} style={{marginTop: `3em`}}>
      <Heading>Contact Top1 Painting</Heading>
      <p className={TextCenter} style={{marginBottom: `64px`}}>
        <strong><a href='tel:+17788591917' title='Contact top1'>
          778.859.1917</a></strong><br /><br />
Please fill out the form below and we will get back to you shortly
      </p>
    </div>

      {/* main text */}
      <div className={RowWrapper}>
      <iframe
          title='contact form'
          style={IStyle}
          src={JotformUrl}
          scrolling='no'
          allowFullScreen='true'
          allowTransparency='true'
          onLoad={() => {
            window.parent.scrollTo(0, 0);
          }}
      />
    </div>
      {/*
       <div className={RowWrapper} style={{ marginBottom: `4em` }}>
       <h3>Our Address</h3>
       <p className={TextCenter}>
       3085 Primrose Lane Coquitlam, BC,
       <br />
       Coquitlam
       <br />
       BC V3B 7S3
       </p>
       <p className={TextCenter}>604.992.2970</p>
       </div>
       */}
  </Layout>
);

export default Contact;